import { ChakraProvider, Box } from "@chakra-ui/react"
import Nav from "../Nav";
import "../css/home.css";
import ScheduledScansAdd from "../ScheduledScansAdd";

const ScheduledScans = () => {
    return (
        <div className="dashboard">
            <ChakraProvider></ChakraProvider>
            <Nav/>
            <Box className="App" minHeight="100vh">
                <ScheduledScansAdd />
            </Box>
        </div>
    )
}

export default ScheduledScans