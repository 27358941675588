import Axios from "axios";
import { errorTypeList } from "../utils/errortypes";
import errorInfo from "../utils/errorInfo.json";

export const urlService = {
  getUrls: async ({ selectedUrl }) => {
    try {
      const { data } = await Axios.post(
        process.env.REACT_APP_BACKEND_URL + "/scans/getUrls",
        { selectedUrl: selectedUrl },
        { headers: { "x-auth-token": localStorage.getItem("auth-token") } },
      );

      return data;
    } catch (err) {
      throw Error("User not authenticated");
    }
  },
  getScoreCardsData: async ({ mainUrl, time }) => {
    const { data } = await Axios.post(
      process.env.REACT_APP_BACKEND_URL + "/scans/scoreCards",
      { mainUrl, time },
      { headers: { "x-auth-token": localStorage.getItem("auth-token") } }
    );

    const { data: burnDownChartData } = await Axios.post(
      process.env.REACT_APP_BACKEND_URL + "/scans/getBurnDownData",
      { mainUrl },
      { headers: { "x-auth-token": localStorage.getItem("auth-token") } }
    );
    return {
      ...data[0],
      burnDownChartData: burnDownChartData.data,
    };
  },
  getLinksAndCssSelectorsData: async ({ mainUrl, time }) => {
    try {
      const { data } = await Axios.post(
        process.env.REACT_APP_BACKEND_URL + "/scans/LinksAndCssSelectors",
        { date: time, link: mainUrl },
        { headers: { "x-auth-token": localStorage.getItem("auth-token") } }
      );
      
      const result = data.map((cssSelector) => {
        const errorTypes = Object.keys(cssSelector.errorList);
        const highPriority = {};
        const lowPriority = {};
        errorTypes.forEach((errorType) => {
          if (!errorInfo.some((error) => error.name === errorType)) return;
          if (errorTypeList[errorType] === "error") {
            highPriority[errorType] = cssSelector.errorList[errorType];
          } else {
            lowPriority[errorType] = cssSelector.errorList[errorType];
          }
        });
        return {
          ...cssSelector,
          highPriority,
          lowPriority,
        };
      });
      return result;
    } catch (err) {

    }
  },
  getLinksAndCssSelectorsForNewErrorData: async (data) => {
    try {       
      const result = data.map((cssSelector) => {
        const errorTypes = Object.keys(cssSelector.errorList);
        const highPriority = {};
        const lowPriority = {};
        errorTypes.forEach((errorType) => {
          if (!errorInfo.some((error) => error.name === errorType)) return;
          if (errorTypeList[errorType] === "error") {
            highPriority[errorType] = cssSelector.errorList[errorType];
          } else {
            lowPriority[errorType] = cssSelector.errorList[errorType];
          }
        });
        return {
          ...cssSelector,
          highPriority,
          lowPriority,
        };
      });
      return result;
    } catch (err) {

    }
  },
};
