import React, { useEffect, useState } from "react";
import adascloginImage from './loginDashboard.png';
import Axios from "axios";
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import Alert from '@material-ui/lab/Alert';
import ErrorNotice from "../misc/ErrorNotice";

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import userActions from "../../store/actions/userActions";
import { useDispatch } from "react-redux";

const useStyles = makeStyles(theme => ({
    root: {
        height: '100vh',
    },
    image: {
        backgroundImage: `url(${adascloginImage})`,
        backgroundRepeat: 'no-repeat',
        backgroundColor:
            theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },
    paper: {
        margin: theme.spacing(8, 4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

const Users = () => {
    const classes = useStyles();

    const [emailOptions, setEmailOptions] = useState([]);
    const [selectedEmail, setSelectedEmail] = useState("");
    const [displayOptions, setDisplayOptions] = useState([]);
    const [selectedDisplayName, setSelectedDisplayName] = useState("");
    const [selectedId, setSelectedId] = useState(null);
    const [deletedId, setDeletedId] = useState(null);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(undefined);
    const [isDisabled, setIsDisabled] = useState(true);
    const [openDialog, setOpenDialog] = useState(false);
    const [callApi, setCallApi] = useState('status');

    const [statusOptions, setStatusOptions] = useState([]);
    const [userStatusApi, setUserStatusApi] = useState(false);
    const [userStatus, setUserStatus] = useState(false);
    const dispatch = useDispatch();

    const handleStatus = (event) => {
        setSuccess(false);
        setError(undefined);
        setOpenDialog(true);
        setCallApi('status');
        setUserStatusApi(event.target.checked);
    };

    useEffect(() => {
        const fetchData = async () => {
            const userListUrl = process.env.REACT_APP_BACKEND_URL + "/users/list";
            try {
                const response = await Axios({
                    method: "get",
                    url: userListUrl,
                    responseType: "json",
                    headers: { "x-auth-token": localStorage.getItem("auth-token") }
                });
                
                if (!response || !response.data) return;
    
                const users = [];
                const email = [];
                const userStatuses = [];
                response.data.users.forEach((user, index) => {
                    email.push({ label: user.email, id: user._id });
                    users.push({ label: `${index + 1}. ${user.displayName || '-'}`, id: user._id });
                    userStatuses[user._id] = user.isActive !== undefined ? user.isActive : true;
                });
    
                setDisplayOptions(users);
                setEmailOptions(email);
                setStatusOptions(userStatuses);
            } catch (error) {
                if (error.response && error.response.status === 401) {
                    dispatch(userActions.logOut());
                } else {
                    console.error("An error occurred while fetching the user list", error);
                }
            }
        };
    
        fetchData();
    }, [dispatch, deletedId]);

    function Copyright() {
        return (
            <Typography variant="body2" color="textSecondary" align="center">
                {'Copyright © '}
                <Link color="inherit" href="http://adasitecompliance.com/">
                    AdaSiteCompliance.com
                </Link> {' '}
                {new Date().getFullYear()}
                {'.'}
            </Typography>
        );
    }

    function deleteUserHandler() {
        setCallApi('status');
        setSuccess(false);
        setError(undefined);
        setOpenDialog(true);
    }

    const handleDialogClose = () => {
        setOpenDialog(false);
    };

    const handleDeleteClick = async () => {
        setOpenDialog(false);
        setIsDisabled(true);
        if (callApi === "delete") {
            deleteUser();
        } else {
            changeUserStatus();
        }
    };

    const deleteUser = async () => {
        const userListUrl = process.env.REACT_APP_BACKEND_URL + "/users";
        await Axios({
            method: "patch",
            url: userListUrl,
            data: {
                user_id: selectedId,
                isActive: !userStatus
            },
            responseType: "json",
            headers: { "x-auth-token": localStorage.getItem("auth-token") }
        }).then(function (response) {
            if (response.status) {
                setDeletedId(selectedId + !userStatus);
                setSelectedEmail(null);
                setSelectedDisplayName(null);
                setSelectedId(null);
                setSuccess(true);
            }
        }).catch(function (error) {
            if (error.response.status === 401) {
                dispatch(userActions.logOut())
            }
            setError(error.response.data?.error);
        });
    };

    const changeUserStatus = async () => {
        const userListUrl = process.env.REACT_APP_BACKEND_URL + "/users";
        await Axios({
            method: "patch",
            url: userListUrl,
            data: {
                user_id: selectedId,
                isActive: !userStatus
            },
            responseType: "json",
            headers: { "x-auth-token": localStorage.getItem("auth-token") }
        }).then(function (response) {
            if (response.status) {
                setUserStatus(userStatusApi);
                setIsDisabled(true);
                setDeletedId(selectedId + !userStatus);
                setSuccess(true);

                setSelectedEmail(null);
                setSelectedDisplayName(null);
                setSelectedId(null);
            }
        }).catch(function (error) {
            if (error.response.status === 401) {
                dispatch(userActions.logOut())
            }
            setError(error.response.data?.error);
        });
    };

    return (
        <Grid container component="main" className={classes.root}>
            <title>User - ADAash</title>
            <CssBaseline />
            <Grid item xs={15} sm={5} md={4} className={classes.image}><p className="sr-only">ADA Site Compliance - ADAsh</p></Grid>
            <Grid item xs={12} sm={7} md={8} component={Paper} elevation={6} square>
                <div className={classes.paper}>
                    <Typography component="h1" variant="h5">Enable/Disable User</Typography>
                    <div className={classes.form}>
                        <Autocomplete
                            options={emailOptions}
                            renderInput={(params) => <TextField {...params} label="Email" />}
                            autoSelect={true}
                            onChange={(event, value) => {
                                if (value === null || value === undefined) {
                                    setSelectedEmail(null);
                                    setSelectedDisplayName(null);
                                    setSelectedId(null);
                                    setIsDisabled(true);
                                    setUserStatus(false);
                                } else {
                                    setSelectedDisplayName(displayOptions.find(i => i.id === value.id));
                                    setSelectedId(value.id);
                                    setIsDisabled(false);
                                    setUserStatus(statusOptions[value.id]);
                                    setSelectedEmail(value);
                                }
                            }}
                            value={selectedEmail}
                        />
                        <Autocomplete
                            options={displayOptions}
                            renderInput={(params) => <TextField {...params} label="Display Name" />}
                            autoSelect={true}
                            onChange={(event, value) => {
                                if (value === null || value === undefined) {
                                    setSelectedEmail(null);
                                    setSelectedDisplayName(null);
                                    setSelectedId(null);
                                    setIsDisabled(true);
                                    setUserStatus(false);
                                } else {
                                    setSelectedEmail(emailOptions.find(i => i.id === value.id));
                                    setSelectedId(value.id);
                                    setIsDisabled(false);
                                    setUserStatus(statusOptions[value.id]);
                                    setSelectedDisplayName(value);
                                }
                            }}
                            value={selectedDisplayName}
                        />
                        <FormGroup>
                            <FormControlLabel required
                                control={<Checkbox checked={userStatus} onChange={handleStatus} inputProps={{ 'aria-label': 'controlled' }} />}
                                disabled={true}
                                label="Is Active" />
                        </FormGroup>
                        {error && (
                            <ErrorNotice aria-label={error} message={error} clearError={() => setError(undefined)} />
                        )}
                        {success && (
                            callApi === "delete" ?
                            <Alert className="scan_complete_alert w-100" onClose={() => setSuccess(false)}>User account deleted successfully!</Alert>
                            :
                            <Alert className="scan_complete_alert w-100" onClose={() => setSuccess(false)}>User status updated successfully!</Alert>
                        )}
                        <Button
                            type="submit"
                            fullWidth
                            style={{ borderColor: "#959595" }}
                            margin="normal"
                            variant="contained"
                            color="primary"
                            value="Delete User"
                            className={classes.submit}
                            aria-hidden="true"
                            aria-disabled="false"
                            onClick={deleteUserHandler}
                            disabled={isDisabled}
                        >
                            {userStatus ? "Disable" : "Enable"} Account
                        </Button>
                        <Grid container>
                            <Grid item xs></Grid>
                            <Grid item></Grid>
                        </Grid>
                        <Box mt={5}>
                            <Copyright />
                        </Box>
                    </div>
                </div>
                <Dialog
                    open={openDialog}
                    onClose={handleDialogClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {callApi === "delete" ?
                            'Are you sure you wish to delete this account?' :
                            `Are you sure you wish to ${userStatus ? "disable" : "active"} this account?`
                        }
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">Account's Name: { (selectedDisplayName !== null && selectedDisplayName !== undefined) ? selectedDisplayName.label : ''}</DialogContentText>
                        <DialogContentText id="alert-dialog-description">E-Mail: { (selectedEmail !== null && selectedEmail !== undefined) ? selectedEmail.label : ''}</DialogContentText>
                        {callApi !== "delete" &&
                        <DialogContentText id="alert-dialog-description">is Active: {userStatus ? 'No' : 'Yes'}</DialogContentText>
                        }
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleDialogClose} color="primary">Cancel</Button>
                        <Button onClick={handleDeleteClick} color="primary" autoFocus>Confirm</Button>
                    </DialogActions>
                </Dialog>
            </Grid>
        </Grid>
    )
}

export default Users;