import ReactGA from 'react-ga4';

// 'ADAshboard' Google Analytics property
// 'ADAshboard - Live' data stream
const InitializeGoogleAnalytics = () => {
    ReactGA.initialize("G-QLEQBHP7XK");
};

//Google Analytics Pageview
const SendAnalytics = ()=> {
    ReactGA.send({
        hitType: "pageview",
        page: window.location.pathname,
    });
}

export default InitializeGoogleAnalytics;
export {InitializeGoogleAnalytics, SendAnalytics };