import React, { useState } from "react";
import Axios from "axios";
import ErrorNotice from "../misc/ErrorNotice";
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField'
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Autocomplete from '@mui/material/Autocomplete';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import adascloginImage from './loginDashboard.png'
import { useDispatch } from "react-redux";
import Alert from '@material-ui/lab/Alert';
import { Delete } from "@material-ui/icons";
import userActions from "../../store/actions/userActions";

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
  },
  image: {
    backgroundImage: `url(${adascloginImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));
export default function Register() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordCheck, setPasswordCheck] = useState("");
  const [isAdminCheck, setIsAdminCheck] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [associatedEntity, setAssociatedEntity] = useState("");
  const [AssociatedURls, setAssociatedURls] = useState([]);
  const [AssociatedURl, setAssociatedURl] = useState("");
  const [displayName, setDisplayName] = useState("");
  const [entityOptions, setEntityOptions] = useState([]);
  const [allEntities, setAllEntities] = useState({});
  const [selectedEntityName, setSelectedEntityName] = useState("");
  const [selectedEntityID, setSelectedEntityID] = useState("");
  const [entityLoading, setEntityLoading] = useState(false);
  const [allWorkOrders, setAllWorkOrders] = useState({});
  const [selectedWorkOrderName, setSelectedWorkOrderName] = useState({});
  const [selectedWorkOrderID, setSelectedWorkOrderID] = useState({});
  const [workOrderNames, setWorkOrderNames] = useState([]);
  const [workOrderLoading, setWorkOrderLoading] = useState(false);
  const [error, setError] = useState();
  const [success, setSuccess] = useState(false);
  
  const classes = useStyles();
  const dispatch = useDispatch();
  var lastRequestTime = 0;
  const submit = async (e) => {
    e.preventDefault();
    setSuccess(false)
    setError(false);

    if (AssociatedURls.length === 0) {
      setError("Please add URL");
      return;
    }

    const regInfo = {
      email: email,
      displayName: displayName,
      firstName: firstName,
      lastName: lastName,
      password: password,
      passwordCheck: passwordCheck,
      associatedEntity: associatedEntity,
      AssociatedURls: AssociatedURls,
      isAdmin: isAdminCheck,
      entityID: selectedEntityID,
      workOrderID: selectedWorkOrderID,
    }
    Axios.post(
      process.env.REACT_APP_BACKEND_URL + '/users/register',
      regInfo,
      { headers: { "x-auth-token": localStorage.getItem("auth-token") } }
    ).then(res => {
      setSuccess(true)
      setEmail("");
      setPassword("");
      setPasswordCheck("");
      setFirstName("");
      setLastName("");
      setAssociatedEntity("");
      setAssociatedURls([]);
      setDisplayName("");
      setIsAdminCheck(false);
      setSelectedEntityName("");
      setSelectedEntityID("");
      setSelectedWorkOrderID("");
      setSelectedWorkOrderName("");
      setWorkOrderNames([]);
      if(res.data.workOrderMissing){
        setError("No 'Related Work Order' Set - Please Set it Manually in Zoho CRM")
      }
    }).catch((err) => {
      if (err.response.status === 401) {
        dispatch(userActions.logOut())
      }
      console.error("error sending user registration: ",err);
      err.response.data.msg && setError(err.response.data.msg);
    });
  };
  function Copyright() {
    return (
      <Typography variant="body2" color="textSecondary" align="center">
        {'Copyright © '}
        <Link color="inherit" href="http://adasitecompliance.com/">
          AdaSiteCompliance.com
        </Link> {' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    );
  }

  function validateUrl(value) {
    return /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(value);
  }

  function addUrl() {
    setError("");
    if (validateUrl(AssociatedURl)) {
      const url = new URL(AssociatedURl)
      if (AssociatedURls.includes(url.origin + "/")) {
        setAssociatedURl("")
        return
      }
      setAssociatedURls(oldArray => [...oldArray, url.origin + "/"]);
      setAssociatedURl("")
    } else {
      setAssociatedURl("")
      setError("Invalid URL. Please add valid URL");
    }
  }

  const removeUrl = (e, key) => {
    e.preventDefault();
    var URls = AssociatedURls.filter(function(value, i){
      return i !== key;
    });
    setAssociatedURls(URls)
  }

  async function getCRMEntities(){
    let elapsedTime = Date.now() - lastRequestTime;
    if(elapsedTime > 10 * 60 * 1000 && entityOptions[10] !== undefined){ // Only fetches new CRM entities if it's been >10 minutes since the last fetch & it already has the entities
      return allEntities;
    } else {
      return Axios.post(process.env.REACT_APP_BACKEND_URL + '/zohoCRM/crmAllEntities')
      .then((response)=>{
        let entities = [];
        response.data.forEach((entity)=>{
          entities.push(entity["Account_Name.Account_Name"]);
        });
        setAllEntities(response.data);
        setEntityOptions(entities);
        lastRequestTime = Date.now();
        return response.data;
      })
    }
  }

  async function getCRMWorkOrders(entityName){
    return Axios.post(process.env.REACT_APP_BACKEND_URL + '/zohoCRM/crmWorkOrders', {"entityName": entityName})
      .then((response)=>{
        let workOrders = response.data.workOrders;
        setAllWorkOrders(workOrders);
        let workOrderNames = [];
        workOrders.forEach((workOrder)=>{
          workOrderNames.push(workOrder.name);
        })
        setWorkOrderNames(workOrderNames);
        return workOrders;
      });
  }

  async function findEntityID(selectedValue){
    if(selectedValue){
      Axios.post(process.env.REACT_APP_BACKEND_URL + '/zohoCRM/searchCRMEntities', {entityName: selectedValue})
      .then((response)=>{
        setSelectedEntityID(response.data.entityID)
      })
    }
  }

  function findWorkOrderID(selectedValue, allWorkOrders){
    if(selectedValue){
      for(let i=0; i<allWorkOrders.length; i++){
        if(allWorkOrders[i].name === selectedValue){
          setSelectedWorkOrderID(allWorkOrders[i].id);
        }
      }
    }
  }


  return (
    <Grid container component="main" className={classes.root}>
      <title>Register - ADAsh</title>
      <CssBaseline />
      <Grid item xs={15} sm={5} md={4} className={classes.image}><p className="sr-only">ADA Site Compliance - ADAsh</p></Grid>
      <Grid item xs={12} sm={7} md={8} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <Typography component="h1" variant="h5">
            User Registration
          </Typography>
          <form className={classes.form} onSubmit={submit}>
            <Typography component="p">* = required field</Typography>
            <Autocomplete
              options={entityOptions}
              renderInput={(params)=> <TextField {...params} label="Zoho CRM Entity *"/>}
              onOpen={async ()=>{
                setEntityLoading(true);
                await getCRMEntities()
                  .then(()=>{ setEntityLoading(false) })
              }}
              autoSelect={true}
              onChange={(event, value)=>{
                setSelectedEntityName(value);
                setSelectedWorkOrderName('');
                setWorkOrderNames([]);
                findEntityID(value);
              }}
              loadingText={'Fetching CRM Entities, Please Wait...'}
              loading={entityLoading}
              value={selectedEntityName}
            />
            <Autocomplete
              options={workOrderNames}
              disabled={!selectedEntityName}
              autoSelect={true}
              value={selectedWorkOrderName != '[object Object]' ? selectedWorkOrderName : ''}
              renderInput={(params)=> <TextField {...params} label="Zoho CRM Related Work Order *"/>}
              loadingText={'Fetching CRM Work Orders, Please Wait...'}
              loading={workOrderLoading}
              onOpen={async ()=>{
                setWorkOrderLoading(true);
                await getCRMWorkOrders(selectedEntityName)
                  .then((result)=>{
                    setWorkOrderLoading(false);
                  })
                  .catch((error)=>{
                    setAllWorkOrders([{name: 'No Work Orders Found - Manual Input Required in CRM', id: 0}]);
                    setWorkOrderNames(['No Work Orders Found - Manual Input Required in CRM']);
                    setSelectedWorkOrderID('');
                    setSelectedWorkOrderName('');
                    setWorkOrderLoading(false);
                    //console.error("Error getting work orders:",error)
                  })
              }}
              onChange={(event, value)=>{
                setSelectedWorkOrderName(value);
                findWorkOrderID(value, allWorkOrders);
              }}
            />
            <TextField
              fullWidth
              margin="normal"
              required
              style={{ borderColor: "#959595" }}
              variant="outlined"
              id='email'
              name='email'
              type='email'
              label='Email Address'
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
              fullWidth
              margin="normal"
              style={{ borderColor: "#959595" }}
              variant="outlined"
              required
              id='displayName'
              name='displayName'
              label='Display Name'
              value={displayName}
              onChange={(e) => setDisplayName(e.target.value)}
            />
            <TextField
              fullWidth
              margin="normal"
              required
              style={{ borderColor: "#959595" }}
              variant="outlined"
              id='firstName'
              name='firstName'
              label='First Name'
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
            <TextField
              fullWidth
              margin="normal"
              required
              variant='outlined'
              style={{ borderColor: "#959595" }}
              id='lastName'
              name='lastName'
              label='Last Name'
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
            <TextField
              fullWidth
              margin="normal"
              style={{ borderColor: "#959595", display: "none" }}
              variant="outlined"
              id='password'
              name='password'
              type='password'
              label='Password'
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <TextField
              fullWidth
              margin="normal"
              style={{ borderColor: "#959595", display: "none" }}
              variant="outlined"
              id='passwordCheck'
              name='passwordCheck'
              type='password'
              label='Re-Enter Password'
              value={passwordCheck}
              onChange={(e) => setPasswordCheck(e.target.value)}
            />
            <FormControlLabel
              control={
                <Checkbox
                  name='isAdmin'
                  id='is_admin'
                  value={true}
                  color='primary'
                  checkbox={isAdminCheck}
                  onChange={(e) => setIsAdminCheck(!isAdminCheck)}
                />
              }
              label="Make New User an Admin?"
            />
            <TextField
              fullWidth
              margin="normal"
              style={{ borderColor: "#959595", display: "none" }}
              variant="outlined"
              id='entity'
              name='associatedEntity'
              label='Associated Entity'
              value={associatedEntity}
              onChange={(e) => setAssociatedEntity(e.target.value)}
            />
            <TextField
              fullWidth
              margin="normal"
              style={{ borderColor: "#959595" }}
              variant="outlined"
              id='urls'
              name='AssociatedURls'
              type='url'
              label='URLs *'
              value={AssociatedURl}
              onChange={(e) => setAssociatedURl(e.target.value)}
            />
            <Button
              style={{ backgroundColor: "#0063ce", color: "#fff", padding: "1rem", float: "right" }}
              type="button"
              value="Individual Scan Button"
              onClick={addUrl}
            >
              Add Url
            </Button>
            <ul>
              {AssociatedURls.map((url, i) =>
                <li key={url}><a href="#" title="Remove URL" onClick={ e => removeUrl(e, i) } ><Delete /></a> {url}</li>
              )}
            </ul>
            {error && (
              <ErrorNotice aria-label={error} message={error} clearError={() => setError(undefined)} />
            )}
            {success && (
              <Alert className="scan_complete_alert" onClose={() => setSuccess(false)}>Account Successfully Created, Invite Link Sent!</Alert>
            )}
            <Button
              type="submit"
              fullWidth
              style={{ borderColor: "#959595" }}
              margin="normal"
              variant="contained"
              color="primary"
              value="Register"
              className={classes.submit}
            >
              Register New User
            </Button>
            <Grid container>
              <Grid item xs>
              </Grid>
              <Grid item>
              </Grid>
            </Grid>
            <Box mt={5}>
              <Copyright />
            </Box>
          </form>
        </div>
      </Grid>
    </Grid>

  );
}
