import React, { useState, useMemo, useEffect, useRef, Fragment } from "react";
import {
  AccordionItem,
  AccordionIcon,
  AccordionPanel,
  AccordionButton,
  Checkbox,
} from "@chakra-ui/react";
import modifiedErrorInfoList from "../../utils/errorInfoData";
import axios from "axios";
import { Progress } from "@chakra-ui/react";
import ReactPrism from "@versant-digital/react-prismjs";
import Highlight from "react-highlighter";
import "./errordetail.css";
import userActions from "../../store/actions/userActions";
import { useDispatch } from "react-redux";

const ErrorDetail = ({ name, count, id, hiddenErrors, onHideError, viewHidden, xpaths }) => {
  const [selected, setSelected] = useState(true);
  const [selectedError, setSelectedError] = useState({});
  const [loading, setLoading] = useState(false);

  let [imageError, setImageError] = useState(false);
  let [codeError, setCodeError] = useState(false);
  let [image, setImage] = useState("");
  let [searchImage, setSearchImage] = useState(false);
  let [loadingCode, setLoadingCode] = useState(false);
  let [loadingImage, setLoadingImage] = useState(false);
  let [codeSnippet, setCodeSnippet] = useState(false);
  let [rawHTML, setRawHTML] = useState(false);
  const buttonRef = useRef(null);

  const dispatch = useDispatch();

  const info = useMemo(() => {
    return modifiedErrorInfoList[name];
  }, [name]);

  const getErrorScreenCap = async () => {
    setLoading(true);
    setImageError(false);
    setCodeError(false);
    try {
      setLoadingImage(true);
      setLoadingCode(true);
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/scans/errorDetail`,
        { errorType: name, id },
        { headers: { "x-auth-token": localStorage.getItem("auth-token") } }
      );

      setImage(`${process.env.REACT_APP_BACKEND_URL}/${response.data.Image}`);
      setLoadingImage(false);
      setLoadingCode(false);
      setCodeSnippet(response.data.element);
      setRawHTML(response.data.rawHTML);
    } catch (error) {
      setLoadingImage(false);
      setImageError(true);

      setLoadingCode(false);
      setCodeError(true);
      if (error.response && error.response.status === 401) {
        dispatch(userActions.logOut());
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    if (selected && searchImage && !image) {
      getErrorScreenCap();
    }
  }, [selected, searchImage, viewHidden, hiddenErrors]);

  useEffect(() => {
    const button = buttonRef.current;
    if (button) {
      const handleMutation = (mutationsList) => {
        mutationsList.forEach((mutation) => {
          if (
            mutation.type === "attributes" &&
            mutation.attributeName === "aria-expanded"
          ) {
            const isExpanded = button.getAttribute("aria-expanded") === "true";
            if (isExpanded) {
              setSearchImage(true);
              setSelected(true);
            }
          }
        });
      };

      const observer = new MutationObserver(handleMutation);
      observer.observe(button, { attributes: true });

      return () => observer.disconnect();
    }
  }, []);

  if (!info) {
    return null;
  }

  const renderErrors = () => {
    return codeSnippet !== false ? (
      codeSnippet.length === 1 ? (
        codeSnippet.map((code, index) => {
          const xpath = xpaths[index];
          const isHidden = hiddenErrors[name] && hiddenErrors[name][xpath];
          if ((viewHidden && !isHidden) || (!viewHidden && isHidden)) {
            return null;
          }

          return (
            <div key={index} className="error-instance">
              <div className="err-instance-heading">
                <span className="instance">Instance {index + 1}: </span>
                <div className="err-instance-wrapper">
                  <Checkbox
                    isChecked={isHidden}
                    onChange={() => onHideError(name, xpath)}
                    className="toggle-hide"
                  />
                  <ReactPrism language="html">
                    <Highlight search>{code}</Highlight>
                  </ReactPrism>
                </div>
              </div>
            </div>
          );
        })
      ) : (
        codeSnippet.map((code, index) => {
          const xpath = xpaths[index];
          const isHidden = hiddenErrors[name] && hiddenErrors[name][xpath];
          if ((viewHidden && !isHidden) || (!viewHidden && isHidden)) {
            return null;
          }

          return (
            <Fragment key={index}>
              <div className="err-instance-heading">
                <span className="instance">Instance {index + 1}:</span>
                <div className="err-instance-wrapper">
                  <Checkbox
                    isChecked={isHidden}
                    onChange={() => onHideError(name, xpath)}
                    className="toggle-hide"
                  />
                  <ReactPrism language="html">
                    <Highlight search={codeError}>{code}</Highlight>
                  </ReactPrism>
                </div>
              </div>
            </Fragment>
          );
        })
      )
    ) : (
      ""
    );
  };

  return (
    <AccordionItem>
      <div onClick={() => { setSelectedError({ [name]: true }); setSearchImage(true); setSelected(true);}}>
        <AccordionButton ref={buttonRef}>
          <div className="url-title__container ">
            <div className="url-title__sub-container">
              <span className="accordion__count accordion__count--sub">
                {count}
              </span>
              <h4 className="accordion__title accordion__title-sub">
                {name}
              </h4>
            </div>
            <AccordionIcon color="#1f3366" width="26" height="26" />
          </div>
        </AccordionButton>
      </div>
      <AccordionPanel pb={4}>
        <div className="accordion__error-detail-container">
          <div className="accordion__error-detail">
            <h5 className="accordion__error-heading ">Initial Scan Errors</h5>
            <p className="accordion__error-paragraph">
              {info ? info.title : ""}
            </p>
            <h5 className="accordion__error-heading ">What It Means</h5>
            <p className="accordion__error-paragraph">
              {info ? info.summary : ""}
            </p>
            <h5 className="accordion__error-heading ">Why It Matters</h5>
            <p className="accordion__error-paragraph">
              {info ? info.whyItMatters : ""}
            </p>
            <h5 className="accordion__error-heading ">In English</h5>
            <p className="accordion__error-paragraph">
              {info ? info.inEnglish : ""}
            </p>
            <h5 className="accordion__error-heading ">How To Fix It</h5>
            <p className="accordion__error-paragraph">
              {info ? info.howToFixIt : ""}
            </p>

            <div className="info__box"></div>
          </div>
          <div className="accordion__error-detail-subcontainer">
            <div className="accordion__button-container ">
              <button
                aria-hidden={selected}
                aria-pressed={!selected}
                aria-label={!selected && "HTML Selected"}
                onClick={() => setSelected(false)}
                className={`accordion__button ${!selected && "accordion__button--selected"}`}
              >
                HTML
              </button>
              <button
                aria-hidden={!selected}
                aria-pressed={selected}
                aria-label={selected && "Preview Selected"}
                onClick={() => setSelected(true)}
                className={`accordion__button ${selected && "accordion__button--selected"}`}
              >
                Preview{" "}
              </button>
            </div>

            <div className="accordion__error-detail-subcontainer-image ">
              {selected ? (
                loadingImage ? (
                  <div>
                    <div className="accordion_congratulation">
                      <div className="congratulation__container">
                        <p className="congratulation__heading">
                          Loading Your Screenshot!
                        </p>
                      </div>
                    </div>
                    <Progress size="xs" isIndeterminate></Progress>
                  </div>
                ) : imageError ? (
                  <div className="accordion_congratulation">
                    <div className="congratulation__container">
                      <p className="congratulation__heading">
                        All According to plan!
                      </p>
                      <p className="congratulation__sub-heading">
                        No highlightable html element found for this error.
                      </p>
                    </div>
                  </div>
                ) : (
                  <img alt="Screen shot of highlighted accessibility errors" src={image} />
                )
              ) : loadingCode ? (
                <div>
                  <div className="accordion_congratulation">
                    <div className="congratulation__container">
                      <p className="congratulation__heading">
                        Loading Your Code!
                      </p>
                    </div>
                  </div>
                  <Progress size="xs" isIndeterminate></Progress>
                </div>
              ) : codeError ? (
                <div className="accordion_congratulation">
                  <div className="congratulation__container">
                    <p className="congratulation__heading">
                      All According to plan!
                    </p>
                    <p className="congratulation__sub-heading">
                      No highlightable html element found for this error.
                    </p>
                  </div>
                </div>
              ) : (
                renderErrors()
              )}
            </div>
          </div>
        </div>
      </AccordionPanel>
    </AccordionItem>
  );
};

export default ErrorDetail;
