export const IS_AUTHENTICATED = "IS_AUTHENTICATED";
export const IS_NOT_AUTHENTICATED = "IS_NOT_AUTHENTICATED";
export const IS_ADMIN = "IS_ADMIN";
export const IS_NOT_ADMIN = "IS_NOT_ADMIN";
export const CHECK_USER_AUTH = "CHECK_USER_AUTH";
export const CHECK_LOGIN = "CHECK_LOGIN";
export const LOG_OUT = "LOGOUT";
export const STORE_URLS = "STORE_URLS";
export const ADD_URL = "ADD_URL";
export const GET_URLS = "GET_URLS";
export const STORE_URLS_DETAILS = "STORE_URLS_DETAILS";
export const GET_SCORE_CARD_DATA = "GET_SCORE_CARD_DATA";
export const STORE_SCORE_DATA = "STORE_SCORE_DATA";
export const GET_LINK_AND_CSS_SELECTORS = "GET_LINK_AND_CSS_SELECTORS";
export const STORE_LINK_AND_CSS_SELECTORS ="STORE_LINK_AND_CSS_SELECTORS";
export const GET_LINK_AND_CSS_SELECTORS_FOR_NEW_ERROR = "GET_LINK_AND_CSS_SELECTORS_FOR_NEW_ERROR";
export const STORE_LINK_AND_CSS_SELECTORS_NEW_ERROR ="STORE_LINK_AND_CSS_SELECTORS_NEW_ERROR";
export const CHANGE_SELECTED_URL = "CHANGE_SELECTED_URL";
export const CHANGE_SELECTED_TIME = "CHANGE_SELECTED_TIME";
export const ADD_NEW_SCAN = "ADD_NEW_SCAN";
export const DELETE_SCAN = "DELETE_SCAN";
export const SET_LOADING = "SET_LOADING";