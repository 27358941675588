import {
  ADD_NEW_SCAN,
  CHANGE_SELECTED_TIME,
  CHANGE_SELECTED_URL,
  STORE_LINK_AND_CSS_SELECTORS,
  STORE_SCORE_DATA,
  STORE_URLS,
  ADD_URL,
  STORE_URLS_DETAILS,
  DELETE_SCAN,
  SET_LOADING,
  STORE_LINK_AND_CSS_SELECTORS_NEW_ERROR
} from "../actions/types";

const initialState = {
  urls: [],
  times: {},
  cache: {},
  cacheLink: {},
  errors: [],
  new_errors: 0,
  selectedUrl: "",
  selectedTime: "",
  selectedScanId: "",
  scoreCardsData: {},
  loading: true
};

export const urlReducer = (state = initialState, action) => {
  switch (action.type) {
    case STORE_URLS:
      let { urls } = action.payload;
      return {
        ...state,
        urls,
        selectedUrl: urls[0],
      };
    case ADD_URL: {
      const newState = { ...state };
      let { url } = action.payload;
      newState.urls.push(url)
      return {
        ...state,
        urls: newState.urls,
        selectedUrl: url,
      };
    }
    case STORE_URLS_DETAILS:
      const { times } = action.payload;
      let url = state.selectedUrl

      // Set selectedUrl and scan from url params
      const urlParams = new URLSearchParams(window.location.search);
      const domainUrl = urlParams.get('domain')
      if (domainUrl !== null && times[domainUrl] !== undefined) {
        url = domainUrl
      }

      let urlData = times[url];
      let selectedTime = urlData ? urlData[urlData.length - 1].time : "No Dates Found";
      let selectedScanId = urlData ? urlData[urlData.length - 1].scanId : "No Dates Found";

      const scan = urlParams.get('scan')
      if (scan != null) {
        const selectedData = urlData.find(url => url.scanId === scan);
        if (selectedData !== undefined) {
          selectedTime = selectedData.time;
          selectedScanId = selectedData.scanId;
        }
      }

      return {
        ...state,
        times,
        selectedUrl: url,
        selectedTime: selectedTime,
        selectedScanId: selectedScanId,
      };
    case STORE_SCORE_DATA:
        const { mainUrl, time } = action.payload;
        return {
          ...state,
          cache: {
            ...state.cache,
            [mainUrl]: {
              ...state.cache[mainUrl],
              [time]: action.payload
            }
          },
          scoreCardsData: action.payload,
          loading: false
        };
      case STORE_LINK_AND_CSS_SELECTORS:
        return {
          ...state,
          cacheLink: {
            ...state.cacheLink,
            [action.payload.mainUrl]: {
              ...state.cacheLink[action.payload.mainUrl],
              [action.payload.time]: action.payload.data
            }
          },
          errors: action.payload.data,
        };
    case STORE_LINK_AND_CSS_SELECTORS_NEW_ERROR:
      return {
        ...state,
        new_errors: action.payload,
        loadingNewError: false
      };
    case CHANGE_SELECTED_URL: {
      const url = action.payload.url;
      let selectedUrlTimes = action.payload.data;
      let times = state.times

      times[action.payload.url] = action.payload.data;
      if (action.payload.data != null) {
        let dateList = [];
        action.payload.data.forEach(url => {
          dateList.push(url.data);
        });

        selectedUrlTimes = dateList;
        times[action.payload.url] = dateList;
      }

      return {
        ...state,
        times,
        selectedUrl: url,
        selectedTime: selectedUrlTimes ? selectedUrlTimes[selectedUrlTimes.length - 1].time : "No Dates Found",
        selectedScanId: selectedUrlTimes ? selectedUrlTimes[selectedUrlTimes.length - 1].scanId : "No Dates Found",
        loading: selectedUrlTimes ? true : false
      };
    }
    case CHANGE_SELECTED_TIME: {
      return {
        ...state,
        selectedTime: action.payload.time,
        selectedScanId: action.payload.scanId,
        loading: true
      };
    }
    case ADD_NEW_SCAN: {
      const { url, pageTitle, time, scanId } = action.payload;
      const newState = { ...state };
      if (!newState.times[url]) {
        newState.times[url] = [];
      }
      newState.times[url].push({ 'time': time, 'name': url, 'pageTitle': pageTitle, 'scanId': scanId });
      return {
        ...newState,
        selectedTime: time,
        selectedScanId: scanId
      }
    }
    case DELETE_SCAN: {
      const { url, time } = action.payload;
      const newState = { ...state };

      let urlIndex = 0
      let urls = []
      newState.times[url].forEach((url, i) => {
        if (url.time === time) {
          urlIndex = i
        } else {
          urls.push(url)
        }
      })
      newState.times[url] = urls;
      let newIndex = (urlIndex - 1 < 1) ? 0 : urlIndex - 1
      let selectedTime = time;
      let selectedScanId = "No Dates Found";
      if (!!newState.times[url] && !!newState.times[url][newIndex]) {
        selectedTime = newState.times[url][newIndex].time;
        selectedScanId = newState.times[url][newIndex].scanId
      }
      if (urls.length === 0) {
        newState.times[url] = null;
      }
      return {
        ...newState,
        selectedTime: selectedTime,
        selectedScanId: selectedScanId
      }
    }
    case SET_LOADING: {
      return {
        ...state,
        loading: true
      }
    }
    default:
      return state;
  }
};
