import ReactGA from 'react-ga4';

const WebsiteScanEvent = (scanCompleted, user) =>{
    if(scanCompleted !== true && scanCompleted !== false){
        scanCompleted=true;
    }
    const action = scanCompleted ? 'website_scan_completed' : 'website_scan_failed';
    const category = 'website_scans';

    ReactGA.event(action, {
        category: category,
        action: action,
        page: window.location.pathname,
        userDisplayName: user.displayName,
        userEmail: user.email,
        User: user.email,
        userUrls: user.urls,
    });
}

export default WebsiteScanEvent;