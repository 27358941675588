import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";

function AdminRoute({ component:Component, ...rest }) {
  const { isChecked, isAuthenticated, user} = useSelector(
    (state) => state.auth
  );

//context being refreshed too many times
  return (
    <Route
      {...rest}
      component={(props) => {
        if(isChecked && isAuthenticated && (user.isAdmin)){
          return  <Component {...props}/>;
        } if(isChecked && isAuthenticated && (!user.isAdmin)){
          return <p>forbidden</p>
        }
        return <Redirect to="/login"></Redirect>;
      }}
    />
  );
}

export default AdminRoute;
