import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import axios from "axios";
import FileSaver from "file-saver";
import { Button } from "@chakra-ui/react";

import "./style.css";
import { UrlSelect } from "../layout/UrlDropDown";
import { DateSelect } from "../layout/DateDropDown";
import userActions from "../../store/actions/userActions";
import IndividualScan from "../IndividualScan";

const SearchBar = (mainLoading) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    selectedUrl, selectedTime, selectedScanId
  } = useSelector(state => state.urls);
  
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const scan = urlParams.get('scan');
    if (selectedScanId && scan != null && scan !== selectedScanId) {
      history.push("/");
    }
  }, [selectedScanId, history]);

  const exportErrors = async () => {
    const ExcelDownloadUrl = process.env.REACT_APP_BACKEND_URL + "/scans/ExcelDownload";
    const authToken = localStorage.getItem("auth-token");
    if (!authToken) {
      throw new Error("No authentication token found");
    }

    const response = await axios({
      method: "post",
      url: ExcelDownloadUrl,
      data: { date: selectedTime, link: selectedUrl },
      headers: { "x-auth-token": authToken },
      responseType: "arraybuffer",
    });

    const blob = new Blob([response.data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
    });
    FileSaver.saveAs(blob, `AccessiblityErrorExport.xlsx`);
  };

  const logOut = () => dispatch(userActions.logOut());

  return (
    <div>
      <title> Dashboard - ADA Site Compliance </title>
      <header>
        <div className="header">
          <h1 className="header__heading">ADAsh&trade;- ADA Site Compliance</h1>
          <div className="header__logo-container">
            <Button style={{ height: "50px", marginTop: "0px", backgroundColor: "#0063ce", color: "#fff" }} onClick={logOut}>
              {" "}
              Logout{" "}
            </Button>
          </div>
        </div>
      </header>
      <div className="search-bar__container">
        <UrlSelect mainLoading={mainLoading.mainLoading}/>
        <div className="search-bar__sub-container">
          <IndividualScan mainLoading={mainLoading.mainLoading}/>
        </div>
        <div className="previous-scan__container">
          <DateSelect exportErrors={exportErrors} mainLoading={mainLoading.mainLoading}/>
        </div>
      </div>

    </div>
  );
};

export default SearchBar;
