import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";

function AuthRoute({ component:Component, ...rest }) {
  const { isChecked, isAuthenticated } = useSelector(
    (state) => state.auth
  );

  if (!isChecked) {
    return <p>Loading</p>;
  }

  return (
    <Route
      {...rest}
      component={(props) => {
        if (isChecked && !isAuthenticated) {
          return <Redirect to="/login"></Redirect>;
        }
        return  <Component {...props}/>;
      }}
    />
  );
}

export default AuthRoute;
