import { IS_AUTHENTICATED, IS_NOT_AUTHENTICATED, LOG_OUT } from "../actions/types";

const initialState = {
  isChecked: false,
  isAuthenticated: false,
  user: {},
};

export const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case IS_AUTHENTICATED:
      const { id, displayName, email, isAdmin } = action.payload;
      return {
        isChecked: true,
        isAuthenticated: true,
        user: {
          id,
          displayName,
          email,
          isAdmin,
        },
      };
      
    case IS_NOT_AUTHENTICATED:
      return {
        isChecked: true,
        isAuthenticated: false,
        user: {},
      };
    case LOG_OUT: 
      localStorage.removeItem('auth-token');
      return {
        isChecked: true,
        isAuthenticated: false,
        user: {},
      };
    default:
      return state;
  }
};
