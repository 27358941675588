import React, { useState, useMemo } from "react";
import {
  Accordion,
  AccordionItem,
  AccordionIcon,
  AccordionPanel,
  AccordionButton,
  Button,
  Flex,
  Box,
  Text,
} from "@chakra-ui/react";
import "./style.css";
import ErrorDetail from "../ErrorDetail";
import Congratulations from "../../assets/congratulation.svg";
import modifiedErrorInfoList from "../../utils/errorInfoData";
import Axios from "axios";
import moment from "moment";
import { useDispatch } from "react-redux";
import urlActions from "../../store/actions/urlActions";
import { Progress } from "@chakra-ui/react";
import Alert from "@material-ui/lab/Alert";
import userActions from "../../store/actions/userActions";

const UrlDetail = ({ count, url, scanComplete, setScanComplete, selectedBadge }) => {
  const [isScanning, setIsScanning] = useState(false);
  const [viewHidden, setViewHidden] = useState(false);
  const [hiddenErrors, setHiddenErrors] = useState({});
  const dispatch = useDispatch();

  const scanAgain = async () => {
    setIsScanning(true);
    const currentTime = moment().format("MM/DD/YYYY, h:mm:ss a");

    const scanResponse = await Axios.post(process.env.REACT_APP_BACKEND_URL + "/scans/scanTargetUrl", {
      url: url.mainUrl,
      targetUrl: url.name,
      currentTime,
      numberOfUrls: 1,
    }, { headers: { "x-auth-token": localStorage.getItem("auth-token") } }).then(response => {
      return response.data;
    }).catch(function (error) {
      if (error.response.status === 401) {
        dispatch(userActions.logOut());
      }
      return { "pageTitle": "" };
    });

    dispatch(urlActions.addNewScan(url.name, scanResponse.pageTitle, currentTime, scanResponse.scanId));
    setIsScanning(false);
    setScanComplete(true);
  };

  const calculatedCount = useMemo(() => {
    let uniqueErrorTypes = new Set();
    const errorList = url.errorList || {};

    if (viewHidden) {
      // Count hidden error types
      Object.keys(hiddenErrors).forEach((errorName) => {
        const hiddenErrorInstances = Object.keys(hiddenErrors[errorName] || {}).filter(
          (key) => hiddenErrors[errorName][key]
        ).length;

        if (hiddenErrorInstances > 0 && modifiedErrorInfoList[errorName]) {
          uniqueErrorTypes.add(errorName);
        }
      });
      return uniqueErrorTypes.size;
    }

    if (selectedBadge === "total_errors" || selectedBadge === "new_errors") {
      Object.keys(errorList).forEach((errorName) => {
        const errorInstances = errorList[errorName].count;
        const hiddenCount = hiddenErrors[errorName]
          ? Object.keys(hiddenErrors[errorName]).filter((key) => hiddenErrors[errorName][key]).length
          : 0;
        const visibleCount = errorInstances - hiddenCount;

        if (visibleCount > 0 && modifiedErrorInfoList[errorName]) {
          uniqueErrorTypes.add(errorName);
        }
      });
      return uniqueErrorTypes.size;
    } else {
      return Object.keys(errorList).reduce((count, errorName) => {
        const error = errorList[errorName];
        const hiddenCount = hiddenErrors[errorName]
          ? Object.keys(hiddenErrors[errorName]).filter((key) => hiddenErrors[errorName][key]).length
          : 0;
        const visibleCount = error.count - hiddenCount;

        if (!error.category || selectedBadge === `${error.category}_errors`) {
          return (visibleCount > 0 && modifiedErrorInfoList[errorName]) ? count + 1 : count;
        }
        return count;
      }, 0);
    }
  }, [url, hiddenErrors, viewHidden, selectedBadge]);

  const variant = useMemo(() => {
    if (calculatedCount >= 10) return "pink";
    if (calculatedCount > 0) return "yellow";
    return "green";
  }, [calculatedCount]);

  const toggleViewHidden = () => {
    setViewHidden(!viewHidden);
  };

  const unhideAllErrors = () => {
    setHiddenErrors({});
    setViewHidden(!viewHidden);
  };

  const handleHideError = (errorName, errorXPath) => {
    setHiddenErrors((prevHiddenErrors) => {
      const updatedErrors = { ...prevHiddenErrors };
  
      if (!url.errorList[errorName] || !url.errorList[errorName].xpaths) {
        return updatedErrors;
      }
  
      url.errorList[errorName].xpaths.forEach((xpath) => {
        if (!updatedErrors[errorName]) {
          updatedErrors[errorName] = {};
        }
        if (xpath === errorXPath) {
          updatedErrors[errorName][xpath] = !updatedErrors[errorName][xpath];
        }
      });

      // Check if all errors are unhidden
      let allUnhidden = true;
      for (const key in updatedErrors) {
        if (Object.values(updatedErrors[key]).some(hidden => hidden)) {
          allUnhidden = false;
          break;
        }
      }

      if (allUnhidden) {
        setViewHidden(false);
      }
  
      return updatedErrors;
    });
  };  

  const listErr = () => {
    if (viewHidden) {
      const hiddenErrorCountMap = {};
      Object.keys(hiddenErrors).forEach((errorName) => {
        const hiddenErrorInstances = Object.keys(hiddenErrors[errorName] || {}).filter(
          (xpath) => hiddenErrors[errorName][xpath]
        ).length;
        if (hiddenErrorInstances > 0 && modifiedErrorInfoList[errorName]) {
          hiddenErrorCountMap[errorName] = hiddenErrorInstances;
        }
      });
  
      return Object.keys(hiddenErrorCountMap).map((errorName) => (
        <ErrorDetail
          name={errorName}
          id={url._id}
          key={url._id + url.time + errorName}
          count={hiddenErrorCountMap[errorName]}
          xpaths={url.errorList[errorName].xpaths}
          domId={url.domid}
          hiddenErrors={hiddenErrors}
          onHideError={handleHideError}
          viewHidden={viewHidden}
        />
      ));
    } else {
      return Object.keys(url.errorList).map((errorName) => {
        const errorInstances = url.errorList[errorName].count;
        const hiddenCount = hiddenErrors[errorName]
          ? Object.keys(hiddenErrors[errorName]).filter((key) => hiddenErrors[errorName][key]).length
          : 0;
  
        const count = errorInstances - hiddenCount;
  
        if (
          count > 0 &&
          (!url.errorList[errorName].category ||
            selectedBadge === "total_errors" ||
            selectedBadge === url.errorList[errorName].category + "_errors" ||
            (selectedBadge === "new_errors" && modifiedErrorInfoList[errorName])) &&
          modifiedErrorInfoList[errorName]
        ) {
          return (
            <ErrorDetail
              name={errorName}
              id={url._id}
              key={url._id + url.time + errorName}
              count={count}
              xpaths={url.errorList[errorName].xpaths}
              domId={url.domid}
              hiddenErrors={hiddenErrors}
              onHideError={handleHideError}
              viewHidden={viewHidden}
            />
          );
        }
        return null;
      });
    }
  };

  return (
    <div className="accordion">
      {isScanning ? (
        <Progress className="progress_bar" size="xs" isIndeterminate></Progress>
      ) : null}
      {scanComplete ? (
        <Alert onClose={() => setScanComplete(false)}>Your scan was ran successfully!</Alert>
      ) : null}
      <Accordion allowToggle>
        <AccordionItem className={`url-title__container--${variant}`} isFocusable={false}>
          <Flex alignItems="center">
            <AccordionButton>
              <div className="url-title__container ">
                <div className="url-title__sub-container">
                  <span className={`accordion__count accordion__count--${variant} `}>
                    {calculatedCount}
                  </span>
                  <h3 className="accordion__title">{url.name}</h3>
                </div>
                <div className="accordion__icon-container">
                  <AccordionIcon color="#1f3366" width="26" height="26" />
                </div>
              </div>
            </AccordionButton>
            <Button onClick={toggleViewHidden} backgroundColor="transparent" className="scan_title">
              {viewHidden ? "View Visible Errors" : "View Hidden Errors"}
            </Button>
            {viewHidden && (
              <Button onClick={unhideAllErrors} backgroundColor="transparent" className="scan_title">
                Un-hide All
              </Button>
            )}
            <Button onClick={scanAgain} backgroundColor="transparent" className="scan_title">Scan Again</Button>
          </Flex>
          <AccordionPanel p={8} className="sub-panel">
            <Accordion allowToggle>
              {count ? listErr() : (
                <div className="accordion_congratulation">
                  <img src={Congratulations} alt="congratulation" />
                  <div className="congratulation__container">
                    <h1 className="congratulation__heading">
                      Congratulations!
                    </h1>
                    <h1 className="congratulation__sub-heading">
                      No critical errors found for this url
                    </h1>
                  </div>
                </div>
              )}
            </Accordion>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </div>
  );
};

export default UrlDetail;
