import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "../src/index.css";
import "../src/style.css";
import "../src/App.css";
import store from "./store";
import {Provider} from 'react-redux';
import {reportWebVitals as sendGA} from './reportWebVitals';
import {InitializeGoogleAnalytics, SendAnalytics} from './utils/google-analytics';

InitializeGoogleAnalytics();

ReactDOM.render(
    <Provider store={store}><App /></Provider>, document.querySelector("#root")
);

sendGA(SendAnalytics());