import React, { useState } from "react";
import { styled, alpha } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
//import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: 10,
    minWidth: 180,
    color: 'rgba(0, 0, 0, 0.6)',
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));

export default function ScanOptions(props) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [openDialog, setOpenDialog] = React.useState(false);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDeleteClick = () => {
    props.onDeleteClick();
    setOpenDialog(false);
  };

  const handleExportClick = () => {
    setAnchorEl(null);
    props.onExportClick();
  }

  const handleDialogOpen = () => {
    setAnchorEl(null);
    setOpenDialog(true);
  };

  const handleCopyURL = () => {
    setAnchorEl(null);
    navigator.clipboard.writeText(process.env.REACT_APP_URL + "?domain=" + props.selectedUrl + "&scan=" + props.selectedScanId);
    props.setShowSuccess("URL copied to clipboard")
    setTimeout(() => props.setShowSuccess(false), 1500);
  }

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  return (
    <>
      <div className="primary-menu">
        <Button
          id="menu-options"
          aria-controls={open ? 'menu-options' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          variant="contained"
          onClick={handleClick}
          endIcon={<KeyboardArrowDownIcon />}
          disabled={props.disabled}
        >
          Options
        </Button>
        <StyledMenu
          id="demo-customized-menu"
          MenuListProps={{
            'aria-labelledby': 'demo-customized-button',
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
        >
          <MenuItem onClick={handleExportClick}>Export Selected Date Errors</MenuItem>
          <MenuItem onClick={handleDialogOpen}>Delete Selected Date Scan</MenuItem>
          <MenuItem onClick={handleCopyURL}>Copy URL Selected Date Scan</MenuItem>
        </StyledMenu>
      </div>
      <div>
        <Dialog
          open={openDialog}
          onClose={handleDialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Delete Selected Date Scan</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you would like to permanently delete this scan?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDialogClose} color="primary">Cancel</Button>
            <Button onClick={handleDeleteClick} color="primary" autoFocus>Confirm</Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
}
