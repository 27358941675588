const errorTypeInfo = require("./errorInfo.json");
const getErrorType = () => {
  const errorTypes = {};
  errorTypeInfo.forEach((errorType) => {
    errorTypes[errorType.name] = errorType.type;
  });
  return errorTypes;
};

const getErrorSummary = () => {
  const errorSummaries = {};
  errorTypeInfo.forEach((errorType) => {
    errorSummaries[errorType.name] = errorType.summary;
  });
  return errorSummaries;
};

const errorSummaryList = getErrorSummary();
const errorTypeList = getErrorType();

module.exports = { errorTypeList, errorSummaryList };
