import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useSelector, useDispatch } from 'react-redux';
import urlActions from '../../store/actions/urlActions';
import userActions from "../../store/actions/userActions";
import Axios from "axios";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: '30px',
    minWidth: 300,
    borderColor: '#80bdff',
  },
  mobileForm: {
    margin: '30px 10px',
    minWidth: 180,
  }
}));

function useFormControlStyles() {
  const matches = useMediaQuery('(max-width:480px)');
  const classes = useStyles();
  return matches ? classes.mobileForm : classes.formControl;
}


export function UrlSelect({ selectedNewUrl='', mainLoading }) {
  const { urls, selectedUrl, selectedTime } = useSelector(state => state.urls);
  const dispatch = useDispatch();
  const controlClass = useFormControlStyles();

  const handleChange = async (event, newValue) => {
    if (!newValue) return;

    dispatch(urlActions.setLoading());

    const response = await Axios.post(
      process.env.REACT_APP_BACKEND_URL + "/scans/getUrls",
      { selectedUrl: newValue },
      { headers: { "x-auth-token": localStorage.getItem("auth-token") } }
    ).catch(function (error) {
      if (error.response.status === 401) {
        dispatch(userActions.logOut())
      }
    });

    let data = null
    if (response.data.length > 0) {
      data = response.data
    }
    dispatch(urlActions.changeSelectedUrl(newValue, data));
  };

  return (
    <div>
      <FormControl className={controlClass}>
        <Autocomplete
          id="UrlSelect"
          value={selectedUrl || selectedNewUrl}
          disableClearable
          options={urls.map((url) => url)}
          onChange={handleChange}
          disabled={!selectedTime || mainLoading}
          renderInput={(params) => (
            <TextField {...params} label="URL Selection" variant="outlined" />
          )}
        />
      </FormControl>
    </div>
  );
}
