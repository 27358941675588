import React, { useState, useEffect } from "react";
import { Button } from "@chakra-ui/react";
import "./style.css";
import { UrlSelect } from "../layout/UrlDropDown";

import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import Alert from '@material-ui/lab/Alert';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import ModeIcon from '@mui/icons-material/Mode';
import DeleteIcon from '@mui/icons-material/Delete';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import userActions from "../../store/actions/userActions";
import ErrorNotice from "../misc/ErrorNotice";

const ScheduledScansAdd = () => {

  const { selectedUrl } = useSelector(state => state.urls);
  const dispatch = useDispatch();

  const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: '30px',
      minWidth: 180,
      borderColor: '#80bdff',

    },
    formControlLg: {
      margin: '30px',
      minWidth: 250,
      borderColor: '#80bdff',

    },
    formControlSm: {
      margin: '30px',
      minWidth: 100,
      borderColor: '#80bdff',

    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    mobileForm: {
      margin: '30px 10px',
      minWidth: 180,
    },
    container: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 200,
    },
  }));

  const time = ["12:00 AM", "12:30 AM", "01:00 AM", "01:30 AM", "02:00 AM", "02:30 AM", "03:00 AM", "03:30 AM", "04:00 AM", "04:30 AM", "05:00 AM",
    "05:30 AM", "06:00 AM", "06:30 AM", "07:00 AM", "07:30 AM", "08:00 AM", "08:30 AM", "09:00 AM", "09:30 AM", "10:00 AM", "10:30 AM", "11:00 AM",
    "11:30 AM", "12:00 PM", "12:30 PM", "01:00 PM", "01:30 PM", "02:00 PM", "02:30 PM", "03:00 PM", "03:30 PM", "04:00 PM", "04:30 PM", "05:00 PM",
    "05:30 PM", "06:00 PM", "06:30 PM", "07:00 PM", "07:30 PM", "08:00 PM", "08:30 PM", "09:00 PM", "09:30 PM", "10:00 PM", "10:30 PM", "11:00 PM", "11:30 PM"];

  const matches = useMediaQuery('(max-width:480px)');
  const classes = useStyles();

  const initialFormState = { "frequency": "", "day": "", "every": "", "date":"", "time": "", "scan": "", "method": "Add" }

  function reducerFn(state, action) {
    if (action.type === "clear") {
      return { "frequency": "", "day": "", "every": "", "date":"", "time": "", "scan": "", "method": "Add" }
    }
    if (action.type === "edit") {
      return { ...action.value, "method": "Edit"}
    }

    let s = { ...state }
    s[action.type] = action.value
    return s
  }

  const [showAlert, setshowAlert] = useState("");
  const [error, setError] = useState("");
  const [stateForm, stateDispatch] = React.useReducer(reducerFn, initialFormState);
  const [reloadList, setReloadList] = useState();

  // Function to format the date
  const formatDate = (dateString, edit) => {
    let formattedDate;
    if (edit) {
      const replaceStr = dateString.replace(/T|:\d\dZ/g, ' ');
      const split = replaceStr.split(' ');
      formattedDate = split[0].replace(/-/g, "/");
    } else {
      formattedDate = dateString.replace(/-/g, "/");
    }
    const date = new Date(formattedDate);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const [stateDateForm, stateDateDispatch] = useState({});

  useEffect(() => {

    const date = new Date();
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
    const day = date.getDate().toString().padStart(2, '0');

    const formattedDate = stateForm.date ? formatDate(stateForm.date, true) : `${year}-${month}-${day}`;
    stateDateDispatch({ type: "date", value: formattedDate });
  }, [stateForm.date]);

  async function submitHandler(e) {
    e.preventDefault();
    const url = process.env.REACT_APP_BACKEND_URL + "/scans/scheduled-scans-add";

    let method = "post";
    if (stateForm.method === "Edit") {
      method = "patch";
    }

    const formattedDate = stateForm.date ? formatDate(stateForm.date) : new Date().toISOString().slice(0, 10);

    await axios({
      method: method,
      url: url,
      data: { ...stateForm, "url": selectedUrl, "date": formattedDate },
      responseType: "json",
      headers: { "x-auth-token": localStorage.getItem("auth-token") }
    }).then(function (response) {
      setshowAlert("Scan Scheduled Successfully")
      setReloadList(Math.random())
      stateDispatch({ type: "clear" })
    }).catch(function (error) {
      if (error.response.status === 401) {
        dispatch(userActions.logOut())
      } else {
        setError(error.response.data.message)
      }
    });
  }

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [selectedId, setSelectedId] = useState("");

  function confirmDeleteScheduler(id) {
    setSelectedId(id)
    setOpenDeleteDialog(true)
  }

  const handleDeleteConfirm = () => {
    const url = process.env.REACT_APP_BACKEND_URL + "/scans/scheduled-scans";
    axios({
      method: "delete",
      url: url,
      data: { id: selectedId },
      responseType: "json",
      headers: { "x-auth-token": localStorage.getItem("auth-token") }
    }).then(function (response) {
      setshowAlert("Scan Schedule Deleted Successfully")
      setReloadList(Math.random())

      stateDispatch({ type: "clear" })
      setSelectedId("")
    }).catch(function (error) {
      if (error.response.status === 401) {
          dispatch(userActions.logOut())
      }
    });

    setOpenDeleteDialog(false);
  };

  function editScheduler(id) {
    const url = process.env.REACT_APP_BACKEND_URL + "/scans/scheduled-scans/view?id=" + id;
    axios({
      method: "get",
      url: url,
      responseType: "json",
      headers: { "x-auth-token": localStorage.getItem("auth-token") }
    }).then(function (response) {
      stateDispatch({ type: "edit", value: response.data.scan })
    }).catch(function (error) {
      if (error.response.status === 401) {
          dispatch(userActions.logOut())
      }
    });
  };

  const [lists, setLists] = useState([]);

  useEffect(() => {
    const url = process.env.REACT_APP_BACKEND_URL + "/scans/scheduled-scans";
    axios({
      method: "post",
      url: url,
      responseType: "json",
      headers: { "x-auth-token": localStorage.getItem("auth-token") }
    }).then(function (response) {
      setLists(response.data.scans)
    }).catch(function (error) {
      if (error.response.status === 401) {
          dispatch(userActions.logOut())
      } else {

      }
    });
  }, [dispatch, reloadList]);

  function formatAMPM(date) {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0'+minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
  }

  function getDate(dateString) {
    const d = new Date(dateString)
    const month = d.getMonth()+1;
    const year = d.getFullYear();
    const date = d.getDate();
    return month + "/" + date + "/" + year + " " + formatAMPM(d);
  }

  return (
    <div>
      <title> Dashboard - ADA Site Compliance </title>
      <header>
        <div className="header">
          <h1 className="header__heading">ADAsh&trade;- Scheduled Scan</h1>
          <div className="header__logo-container"></div>
        </div>
      </header>
      <form className="search-bar__container" onSubmit={submitHandler}>
        <UrlSelect selectedNewUrl={stateForm.url}/>
        <div>
          <FormControl style={{ width: "100px !important" }} className={!matches ? classes.formControl : classes.mobileForm}>
            <Autocomplete
              id="frequency"
              disableClearable
              options={["Every", "Run Once"]}
              value={stateForm.frequency}
              onChange={(event, value) => stateDispatch({ type: "frequency", value: value })}
              renderInput={(params) => <TextField {...params} label="Frequency" variant="outlined" required={true} />}
            />
          </FormControl>
        </div>
        {stateForm.frequency === "Run Once" ?
          <div>
            <FormControl style={{ width: "10rem" }} className={!matches ? classes.formControl : classes.mobileForm}>
              <TextField
                required
                id="date"
                label="Date"
                value={stateDateForm.value}
                variant="outlined"
                className={classes.textField}
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  min: new Date().toISOString().slice(0, 10),
                }}
                onChange={(e) => stateDispatch({ type: "date", value: e.target.value })}
              />
            </FormControl>
          </div>
          :
          <>
            <div>
              <FormControl className={!matches ? classes.formControlSm : classes.formControlSm}>
                <Autocomplete
                  required
                  id="day"
                  disableClearable
                  options={["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20"]}
                  value={stateForm.day.toString()}
                  onChange={(event, value) => stateDispatch({ type: "day", value: value })}
                  renderInput={(params) => <TextField {...params} label="Day" variant="outlined" required={true} />}
                />
              </FormControl>
              <FormControl className={!matches ? classes.formControl : classes.mobileForm}>
                <Autocomplete style={{ minWidth: "100px" }}
                  required
                  id="every"
                  disableClearable
                  options={["Day", "Week", "Month", "Year"]}
                  value={stateForm.every}
                  onChange={(event, value) => stateDispatch({ type: "every", value: value })}
                  renderInput={(params) => <TextField {...params} label="Every" variant="outlined" required={true} />}
                />
              </FormControl>
            </div>
          </>
        }
        <div>
          <FormControl style={{ width: "10rem" }} className={!matches ? classes.formControl : classes.mobileForm}>
            <Autocomplete
              required
              id="time"
              disableClearable
              options={time}
              value={stateForm.time}
              onChange={(event, value) => stateDispatch({ type: "time", value: value })}
              renderInput={(params) => <TextField {...params} label="Time" variant="outlined" required={true} />}
            />
          </FormControl>
        </div>
        <div>
          {showAlert ? (
            <Alert onClose={() => setshowAlert(false)}>{showAlert}</Alert>
          ) : null}

          {error && (
            <ErrorNotice aria-label={error} message={error} clearError={() => setError(undefined)} />
          )}

          <FormControl style={{ width: "10rem" }} className={!matches ? classes.formControlLg : classes.mobileForm}>
            <Autocomplete
              required
              id="scan"
              disableClearable
              options={["Scan Current Url", "Run Admin-Only Full-Site Scan", "Run client-Visible Full-Site Scan"]}
              value={stateForm.scan}
              onChange={(event, value) => stateDispatch({ type: "scan", value: value })}
              renderInput={(params) => <TextField {...params} label="Scan" variant="outlined" required={true} />}
            />
          </FormControl>
        </div>
        <Button
          style={{ backgroundColor: "#0063ce", color: "#fff", padding: "1rem", float: "right" }}
          type="submit"
        >{stateForm.method}</Button>
        <Button
          style={{ backgroundColor: "#0063ce", color: "#fff", padding: "1rem", float: "right" }}
          onClick={(event, value) => stateDispatch({ type: "clear" })}
          type="reset"
        >
          Cancel
        </Button>
      </form>
      <div className="search-bar__container mt-2">
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>URL</TableCell>
                <TableCell align="center">Frequency</TableCell>
                <TableCell align="center">Scan</TableCell>
                <TableCell align="center">Next Scan Date</TableCell>
                <TableCell align="center">Last Scan Status</TableCell>
                <TableCell align="center">Type</TableCell>
                <TableCell align="center">Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {lists.map((list) => (
                <TableRow
                  key={list._id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">{list.url}</TableCell>
                  <TableCell align="center">{list.frequency}</TableCell>
                  <TableCell align="center">{
                    list.frequency === "Run Once" ? getDate(list.nextScanDate) : "Every " + list.day + " " + list.every + " at " + list.time
                  }
                  </TableCell>
                  <TableCell align="center">{getDate(list.nextScanDate)}</TableCell>
                  <TableCell align="center">{ list.status.toUpperCase() }</TableCell>
                  <TableCell align="center">{list.scan}</TableCell>
                  <TableCell align="center">
                    <button onClick={() => editScheduler(list._id)}><ModeIcon /></button>
                    <button onClick={() => confirmDeleteScheduler(list._id)}><DeleteIcon /></button>
                  </TableCell>
                </TableRow>
              ))}
              {lists.length === 0 ?
                <TableRow>
                  <TableCell align="center" colSpan={6}>No scheduled scan added</TableCell>
                </TableRow> : ""}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <div>
        <Dialog
          open={openDeleteDialog}
          onClose={() => setOpenDeleteDialog(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Delete Scheduled Scan</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you would like to permanently delete this Scheduler ?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenDeleteDialog(false)} color="primary">Cancel</Button>
            <Button onClick={handleDeleteConfirm} color="primary" autoFocus>Confirm</Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
};

export default ScheduledScansAdd;
