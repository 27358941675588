import React, { useContext, useState, useEffect, useMemo, useRef } from "react";
import { CircularProgressbarWithChildren, buildStyles } from "react-circular-progressbar";
import ProgressBar from "@ramonak/react-progress-bar";
import "react-circular-progressbar/dist/styles.css";
import "./style.css";
import { useDispatch, useSelector } from "react-redux";
import UserContext from "../../context/UserContext";
import urlActions from "../../store/actions/urlActions";
import modifiedErrorInfoList from "../../utils/errorInfoData";

const GeneralMetrics = () => {
  const dispatch = useDispatch();
  const [totalErrorTypeval, setTotalErrorType] = useState(0);
  const { setTotalErrors } = useContext(UserContext);
  const { selectedUrl, selectedTime, scoreCardsData, errors } = useSelector((state) => state.urls);
  const totalErrorsAvailable = Object.keys(modifiedErrorInfoList).length

  const prevUrl = useRef(selectedUrl);
  const prevTime = useRef(selectedTime);
  const errorCache = {}; // Initialize the cache object

  useEffect(() => {
    if (selectedUrl && selectedTime) {
      if (selectedUrl !== prevUrl.current || selectedTime !== prevTime.current) {
        dispatch(urlActions.getScoreCardData(selectedUrl, selectedTime));
        dispatch(urlActions.getLinksAndCssSelectors(selectedUrl, selectedTime));

        prevUrl.current = selectedUrl;
        prevTime.current = selectedTime;
      }
    }
  }, [dispatch, selectedUrl, selectedTime]);

  // Helper function to calculate total errors and types
  const calculateErrorStats = (errors, errorInfoList) => {
    let totalErrorType = 0;
    let listedErrors = 0;
    if (errors?.length) {
      // Calculate total types of errors
      Object.keys(errors[0].errorList).forEach(errorName => {
        if (errorInfoList.hasOwnProperty(errorName)) {
          totalErrorType++;
        }
      });

      // Calculate total listed errors
      errors.forEach(error => {
        Object.keys(error.errorList).forEach(errorName => {
          if (errorInfoList.hasOwnProperty(errorName)) {
            listedErrors += error.errorList[errorName].count;
          }
        });
      });
    }

    return { totalErrorType, listedErrors };
  };

  // Calculating compliance
  const scoreCalculator = (complianceData, totalErrorType, totalErrorsAvailable) => {
    const displayedErrors = totalErrorsAvailable > 0 ? (totalErrorType / totalErrorsAvailable * 100) : 0;
    let health = complianceData.totalNumberOfErrors ? (100 - displayedErrors.toFixed(0)) : 100;
    return Math.max(health, 0); 
  };

  // Calculating percentage of elements with and without errors
  const errorCalculator = ({ totalNumberOfErrors, totalNumberOfElements }) => {
    const elementWithErrorPercentage = totalNumberOfElements > 0 ? 
      Math.floor((totalNumberOfErrors / totalNumberOfElements) * 100) : 0;
    return {
      elementWithErrors: elementWithErrorPercentage,
      elementWithoutErrors: 100 - elementWithErrorPercentage
    };
  };

  // Function to get errors with caching
  const getErrorsWithCache = (selectedUrl, selectedTime, errors, errorInfoList) => {
    const cacheKey = `${selectedUrl}-${selectedTime}`;
    // Check if the result is already cached
    if (errorCache[cacheKey]) {
      return errorCache[cacheKey];
    }

    // Calculate errors if not cached
    const { totalErrorType, listedErrors } = calculateErrorStats(errors, errorInfoList);

    // Store the result in cache
    errorCache[cacheKey] = { totalErrorType, listedErrors };

    return { totalErrorType, listedErrors };
  };

  
  useEffect(() => {
      if (errors != null && errors.length > 0) {
        const { totalErrorType, listedErrors } = getErrorsWithCache(selectedUrl, selectedTime, errors, modifiedErrorInfoList);
        setTotalErrors(listedErrors);
        setTotalErrorType(totalErrorType);
      }
  }, [errors,selectedTime, selectedUrl]);


  // Define constants for risk levels and colors outside the component to avoid redefinition on each render
  const RISK_LEVELS = {
    LOW: { scoreThreshold: 90, colorClass: "risk risk--green", word: "Low Risk", chartColor: "#27736D" },
    MEDIUM: { scoreThreshold: 70, colorClass: "risk risk--yellow", word: "Mid Risk", chartColor: "#fac515" },
    HIGH: { scoreThreshold: 0, colorClass: "risk risk--red", word: "High Risk", chartColor: "#f5767c" },
  };

  // Function to determine risk level based on score
  const determineRiskLevel = (score) => {
    if (score >= RISK_LEVELS.LOW.scoreThreshold) {
      return RISK_LEVELS.LOW;
    } else if (score >= RISK_LEVELS.MEDIUM.scoreThreshold) {
      return RISK_LEVELS.MEDIUM;
    }
    return RISK_LEVELS.HIGH;
  };

  const score = useMemo(() => {
    // Early return for undefined or incomplete data
    if (!scoreCardsData?.firstComplianceScore) {
      return {
        firstComplianceScore: 0,
        complianceScoreProgress: 0,
        currentComplianceScore: 0,
        elementWithErrors: 0,
        elementWithoutErrors: 0,
        firstComplianceRiskColor: '',
        firstComplianceRiskWord: '',
        currentComplianceRiskColor: '',
        currentComplianceRiskWord: '',
        firstRiskChartColor: '',
        currentRiskChartColor: '',
      };
    }

    let firstComplianceScore = errors?.[0]?.intial_compliance_score || 0;
    const currentComplianceScore = scoreCalculator(scoreCardsData, totalErrorTypeval, totalErrorsAvailable);
    const complianceScoreProgress = currentComplianceScore - firstComplianceScore;
    const { elementWithErrors, elementWithoutErrors } = errorCalculator(scoreCardsData);

    const currentRisk = determineRiskLevel(currentComplianceScore);
    const firstRisk = determineRiskLevel(firstComplianceScore);

    return {
      firstComplianceScore,
      complianceScoreProgress,
      currentComplianceScore,
      elementWithErrors,
      elementWithoutErrors,
      firstComplianceRiskColor: firstRisk.colorClass,
      firstComplianceRiskWord: firstRisk.word,
      currentComplianceRiskColor: currentRisk.colorClass,
      currentComplianceRiskWord: currentRisk.word,
      firstRiskChartColor: firstRisk.chartColor,
      currentRiskChartColor: currentRisk.chartColor,
    };
  }, [scoreCardsData, errors, totalErrorTypeval, totalErrorsAvailable]);

  let element = document.createElement("span");
  element.style.position = "absolute";
  element.style.left = "-99999px";
  for (
    let i = 0;
    i < document.getElementsByClassName("MuiButtonBase-root").length;
    i++
  ) {
    document.getElementsByClassName("MuiButtonBase-root")[i].appendChild(element);
  }
  for (
    let i = 0;
    i < document.getElementsByClassName("MuiIconButton-root").length;
    i++
  ) {
    document.getElementsByClassName("MuiIconButton-root")[i].appendChild(element);
  }

  return (
    <div>
      <h2 className="metrics__heading">General Metrics</h2>
      <div className="metrics__container">
        <div className="compliance-score__container">
          <div className="compliance-score__container--current">
            <div className="compliance-score__detail">
              <h3 className="score-heading">Current Compliance Score</h3>
              <p className={score.currentComplianceRiskColor}>{score.currentComplianceRiskWord}</p>

            </div>
            <div className="percentage-container">
              <CircularProgressbarWithChildren
                value={score.currentComplianceScore}
                styles={buildStyles({
                  textColor: score.currentRiskChartColor,
                  pathColor: score.currentRiskChartColor,
                  trailColor: "#F0F0F6",
                })}
              >
                <div className="current-percent" style={{ color: score.currentRiskChartColor }}>
                  <strong>{score.currentComplianceScore}%</strong>
                </div>
              </CircularProgressbarWithChildren>
            </div>
          </div>
          <div className="initial-compliance">
            <div className="compliance-subcontainer">
              <div className="compliance-score__detail">
                <h3 className="score-heading">Initial Compliance Score</h3>
                <p className={score.firstComplianceRiskColor}>{score.firstComplianceRiskWord}</p>

                <p></p>
              </div>
              <div className="percentage-subcontainer">
                <CircularProgressbarWithChildren
                  value={score.firstComplianceScore}
                  styles={buildStyles({
                    textColor: score.firstRiskChartColor,
                    pathColor: score.firstRiskChartColor,
                    trailColor: "#F0F0F6",
                  })}
                >
                  <div className="initial-percent" style={{ color: score.firstRiskChartColor }}>
                    <strong>{score.firstComplianceScore}%</strong>
                  </div>
                </CircularProgressbarWithChildren>
              </div>
            </div>
            <div className="compliance-subcontainer compliance-subcontainer--progress">
              <div className="compliance-score__detail">
                <h3 className="score-heading">Compliance score Progress</h3>
              </div>
              <div className="percentage-subcontainer">
                <CircularProgressbarWithChildren
                  value={score.complianceScoreProgress}
                  styles={buildStyles({
                    textColor: "red",
                    pathColor: "#27736D",
                    trailColor: "#F0F0F6",
                  })}
                >
                  <div className="compliance-percent">
                    <strong>{score.complianceScoreProgress}%</strong>
                  </div>
                </CircularProgressbarWithChildren>
              </div>
            </div>
          </div>
        </div>
        <div className="errors-container">
          <div className="errors-progress">
            <div className="error-subcontainer">
              <h3 className="score-heading">Elements without errors</h3>
              <span className="error_percent error_percent--no-error ">
                {isNaN(score.elementWithoutErrors) ? 0 : score.elementWithoutErrors}%
              </span>
            </div>
            <div>
              <ProgressBar
                height="0.5rem"
                isLabelVisible={false}
                completed={score.elementWithoutErrors}
                bgColor="#27736D"
              />
            </div>
          </div>
          <div>
            <div className="error-subcontainer">
              <h3 className="score-heading">Elements with errors</h3>
              <span className="error_percent error_percent--no-error ">
                {isNaN(score.elementWithErrors) ? 0 : score.elementWithErrors}%
              </span>
            </div>
            <div>
              <ProgressBar
                completed={score.elementWithErrors}
                height="0.5rem"
                isLabelVisible={false}
                bgColor="#f5767c"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GeneralMetrics;
