import React, { useState, useContext } from "react";
import { Redirect } from "react-router-dom";
import UserContext from "../../context/UserContext";
import Axios from "axios";
import ErrorNotice from "../misc/ErrorNotice";
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link  from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import adascloginImage from './loginDashboard.png'
import { Link as RouterLink} from 'react-router-dom'
import { useDispatch, useSelector } from "react-redux";
import userActions from "../../store/actions/userActions";
const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
  },
  image: {
    backgroundImage: `url(${adascloginImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));
export default function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState();
  const { setUserData, setRedirect, setLoadingPage } = useContext(UserContext);
  const { isChecked, isAuthenticated } = useSelector(
    (state) => state.auth
  );
  const classes = useStyles();
  const dispatch = useDispatch();
  if(!isChecked ) {
    return <p>Loading</p>
  }
  if(isChecked &&  isAuthenticated) {
    return <Redirect to="/"/>
  }
  const submit = async (e) => {
    e.preventDefault();
    try {
      dispatch(userActions.login(email, password));
      const loginUser = { email, password };
      const loginRes = await Axios.post(
        process.env.REACT_APP_BACKEND_URL + "/users/login",
        loginUser
      );

      await setUserData({
        token: loginRes.data.token,
        user: loginRes.data.user,
        email: loginRes.data.email,
        urls: loginRes.data.user.urls,
        isAdmin: loginRes.data.user.isAdmin
      });
      localStorage.setItem("auth-token", loginRes.data.token);
      localStorage.setItem("urls", JSON.stringify(loginRes.data.user.urls));

      setRedirect(false);
      setLoadingPage(false);
    } catch (err) {
      if (err.response) {
        err.response?.data?.msg && setError(err.response.data.msg);
      } else {
        setError("Unable to login");
      }
    }
  };
  function Copyright() {
    return (
      <Typography variant="body2" color="textSecondary" align="center">
        {'Copyright © '}
        <Link color="inherit" href="https://adasitecompliance.com/">
        AdaSiteCompliance.com
        </Link> {' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    );
  }



  return (
    <Grid container component="main" className={classes.root}>
    <title>Login  - ADAash</title>
      <CssBaseline />
      <Grid item xs={15} sm={5} md={4} className={classes.image}><p className="sr-only">ADA Site Compliance - ADAsh</p></Grid>
      <Grid item xs={12} sm={7} md={8} component={Paper} elevation={6} square>
        <div className={classes.paper}>

          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          {error && (
            <ErrorNotice  aria-label={error} message={error} clearError={() => setError(undefined)} />
          )}
          <form className={classes.form} onSubmit={submit} noValidate="novalidate">
            <TextField

              style={{ borderColor:"#959595 !important"}}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              id="login-email"
              type="email"
              onChange={(e) => setEmail(e.target.value.toLowerCase())}
            />
            <TextField

              style={{ borderColor:"#959595"}}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              autoComplete="current-password"
              id="login-password"
              onChange={(e) => setPassword(e.target.value)}
            />
            <FormControlLabel
              control={<Checkbox aria-hidden="true"  aria-disabled="false" name="Remember Me" value="Remember Me" color="primary" />}
              label="Remember me"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              value="Log in"
              className={classes.submit}
              aria-hidden="true"
              aria-disabled="false"
            >
              Sign In
            </Button>
            <Grid container>
              <Grid item xs>
                <RouterLink style= {{textDecoration: "underline"}} to="/forgot"  variant="body2">
                  Forgot password?
                </RouterLink>
              </Grid>
              <Grid item>
              </Grid>
            </Grid>
            <Box mt={5}>
              <Copyright />
            </Box>
          </form>
        </div>
      </Grid>
    </Grid>

  );
}
